import React from 'react'
import './Contact.css'
import Footer from './Footer'
import Navbar from "./Navbar"


function Contact() {
  return (

           <>
           <Navbar/>
         <div className="contactusall" style={{ backgroundImage: "url(/img/b.jpg)" }}>
          <div className="contacuspaps">
          <div className="contacinconet">
            <div className="contactusrapper">
            <form >
                <div class="form">
                    <div class="form-txt">
                        <h1>Contact Us</h1>
                        <span>As you might expect of a company that began as a high-end interiors contractor, we pay strict
                        attention.</span>
                        <h3>UK</h3>
                        <p> Marlin Park, Feltham TW14 0AN, United Kingdom <br/>+44 7451284672</p>
                        <h3>USA</h3>
                        <p> 11511 Katy Fwy, Houston, TX 77079 <br/> +1(713)-588-6105</p>
                        
                    </div>
                    <div class="form-details">
                        <input type="text" name="name" id="name" placeholder="Name" required/>
                        <input type="email" name="email" id="email" placeholder="Email" required />
                        <textarea name="message" id="message" cols="52" rows="7" placeholder="Message" required></textarea>
                        <button>SEND MESSAGE</button>
                    </div>
                </div>
            </form>
            </div>
         </div>
          </div>
         </div>
         <Footer/>
           </>
        
 
        
  )
}

export default Contact