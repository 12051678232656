import React from "react";
import "./OneBest.css"

function OneBest() {
  return (
    <div>
      <div className="onebestcontainer">
        <div className="onebesthea">
          <p>One of the Best</p>
          <h2>Vetex  CARGO</h2>
          <hr/>
        </div>
        <div className="onebestcontent">
          <div className="onebestleft">
            <p>
              <span className="thehs">Vetex  Cargo</span> taps into breakthrough
              technologies and powerful trade lanes so you can focus on your
              customers. Ship with confidence—tracking each freight journey from
              origin to customer from your convenient online portal.
            </p>

            <p>
              Since the start of the millennium, we’ve built each partnership
              and strategy around unique client goal posts and business
              challenges. There is no playbook or one-size-fits-all
              approach—every organization is different. Our experts thrive in
              disruption—delivering passion and more intelligent
              solutions—on-time and world-wide.
            </p>
          </div>
          <div className="onebestright">
            <div className="allrighte">
            The Vetex  Cargo difference:
            </div>
            <div className="onebestrightcontainer">
                <ul>
                <li>Reach your customers everywhere, on-time, every time</li>
                   <li>Rely on consistent results shaped by best practices and ISO standards</li>
                   <li>Streamline freight shipments with one global Cargo solutions partner</li>
                    <li>Unleash greater value while driving the best possible outcomes</li>
                   <li>Navigate disruption with a proven supply chain management partner</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneBest;
