import React from "react";
import "./OneBest.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import DeliverPassion from "./DeliverPassion";

function About() {
  return (
    <div>
      <Navbar />
      <div className="onebestcontainer">
        <div className="onebesthea">
          <h2>About Us</h2>
          <hr />
        </div>
        <div className="onebestcontent">
          <div className="onebestleft">
            <p>
              <span className="thehs">Vetex  logistic international, Inc</span>{" "}
              taps into breakthrough technologies and powerful trade lanes so
              you can focus on your customers. Ship with confidence—tracking
              each freight journey from origin to customer from your convenient
              online portal.
            </p>

            <p>
              At Vetex , our mission to provide unparalleled quality, value, and
              growth never ends. Since the start of the millennium, Vetex 
              Cargo has built our global presence, reputation,
              and partnerships on a passion for the details. Our entrepreneurial
              spirit and pragmatic approach—assessing every client’s unique
              challenges and business needs—have fueled our powerful global
              presence spanning six continents and clients of every size across
              sectors.
            </p>

            <p>
              At Vetex  Cargo, our experts thrive in disruption,
              meeting the challenge and the moment in the most unpredictable
              global trade transportation cycles. That’s why we continue to
              outpace the market and expand our presence and network around the
              globe. And while the industry continues to evolve, one thing
              hasn’t changed. At Vetex , every business member is driven by
              supporting our clients and providing a best-in-class experience.
            </p>
          </div>
          <div className="onebestright">
            <div className="allrighte">The Vetex  Cargo difference:</div>
            <div className="onebestrightcontainer">
              <p>
                We continue breaking the norms to find strategic, creative
                approaches that propel remarkable outcomes—across all our
                service offerings. From International Air to Ocean
                Transportation, to Customs Brokerage, Local and Domestic
                Training, Warehouse Management, Global Supply Chain Management,
                3PL, Risk Management, Trade Compliance, and E-Commerce, we
                deliver more. And with offices in over 70 countries, our network
                and reach create more opportunity and flexibility for our
                customers.
              </p>

              <strong className="text-danger">Our approach is anything but out-of-the-box.</strong>

              <p>At Vetex , we are constantly expanding our reach to better serve our international client base. Our commanding global presence includes over forty locations around the world. Learn what’s possible with a partner who goes further than borders and boundaries.

Let's Talk</p>
            </div>
          </div>
        </div>
      </div>
<DeliverPassion/>
      <Footer />
    </div>
  );
}

export default About;
