import React from "react";
import Navbar  from "./Navbar"
import Footer from "./Footer"
import "./Services.css"
import Progre from './DeliverPassion'
import { MdFlight, MdOutlineSettings, MdComputer} from "react-icons/md";
import {TbShip} from 'react-icons/tb'
import {BiWorld} from 'react-icons/bi'
import {BsTruck} from 'react-icons/bs'

function Services() {
  return (
    <div>
        <Navbar/>
      <div className="servi" style={{ backgroundImage: "url(/img/b.jpg)" }}>
        <div className="servicecontent">
          <div className="serbocerid">
            <div className="serviceheader">Explore our services</div>
            <div className="gridcontainer">
            <div className="servicegrid">
              <div className="iconsectionservice">
                <div className="iconservice">
                  <MdFlight />
                </div>
                <div className="icontittle">Air Freight</div>
              </div>
              <div className="inforservice">
                Reduce shipping times and costs with Vetex  group’s right-sized
                air freight solutions.
              </div>
            </div>

            <div className="servicegrid">
              <div className="iconsectionservice">
                <div className="iconservice">
                  <TbShip />
                </div>
                <div className="icontittle">Ocean Freight</div>
              </div>
              <div className="inforservice">
              Accelerate shipping timeframes and maximize value while Vetex  manages your largest shipments.
              </div>
            </div>


            <div className="servicegrid">
              <div className="iconsectionservice">
                <div className="iconservice">
                  <MdOutlineSettings />
                </div>
                <div className="icontittle">
Global Supply Chain Management</div>
              </div>
              <div className="inforservice">
              Unlock a competitive edge with objective insights and guidance from our experienced supply chain management consultants.
              </div>
            </div>

            <div className="servicegrid">
              <div className="iconsectionservice">
                <div className="iconservice">
                  <BiWorld />
                </div>
                <div className="icontittle">
Warehousing and Distribution</div>
              </div>
              <div className="inforservice">
              Rest assured with Vetex  International managing products of every size with precision, security, and professional handling—every step of the way.
              </div>
            </div>

            <div className="servicegrid">
              <div className="iconsectionservice">
                <div className="iconservice">
                  <BsTruck />
                </div>
                <div className="icontittle">
Inland
Trucking</div>
              </div>
              <div className="inforservice">
              Tap into our vast trucking network for reliable inland transportation—anywhere.
              </div>
            </div>

            <div className="servicegrid">
              <div className="iconsectionservice">
                <div className="iconservice">
                  <MdComputer/>
                </div>
                <div className="icontittle">Cross-Border
E-Commerce</div>
              </div>
              <div className="inforservice">
              Meet compliance and consumer expectations with our end-to-end, global E-Commerce solutions.
              </div>
            </div>


            </div>
          </div>
        </div>
      </div>
      <Progre/>
      <Footer/>
    </div>
  );
}

export default Services;
