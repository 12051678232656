import React from 'react'
import Banner from './Banner'
import DeliverPassion from './DeliverPassion'
import Footer from './Footer'
import Global from './Global'
import Navbar from "./Navbar"
import OneBest from './OneBest'

function Home() {
  return (
    <div>
      <Navbar/>
      <Banner/>
      <OneBest/>
      <Global/>
      <DeliverPassion/>
      <Footer/>
    </div>
  )
}

export default Home