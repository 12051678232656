import React, { useState } from "react";
import {
  TiSocialFacebookCircular,
  TiSocialTwitterCircular,
  TiSocialYoutube,
  TiSocialInstagram,
} from "react-icons/ti";
import { getUsersEmail } from "../../Services/GetUser.service";

import "./Footer.css"

function Footer() {
  // const [email, setEmail] = useState("");
  // const [message, setmesage] = useState("");
  // const [loader, setLoader] = useState(false);


  // function getaccount(event) {
  //   setEmail(event.target.value);
  //   const email = event.target.value;
  //   if (email) {
  //     getUsersEmail(email).then((newUsers) => {
  //       // setloading(true);
  //       const newUser = newUsers[0];
  //       setEmail(newUser ? newUser.email : "");
  //     });
  //   }
  // }

  // const handleNewSubmit = (e) => {
  //   e.preventDefault();

  //   if (email === ""){
  //     setmesage("Value required");
  //     return;
  //   }
  //   if (email) {
  //     setmesage("Hi, you have subscribe to this service. Nice to meet you");
  //     return;
  //   }
  //   setLoader(true);

  //   database
  //     .collection("newsletter")
  //     .add({
  //       email: email,
  //       User:"Logistics"
  //     })
  //     .then(() => {
  //       setLoader(false);
  //       setmesage("Your message has been submitted👍");
  //     })
  //     .catch((error) => {
  //       alert(error.message);
  //       setLoader(false);
  //     });

  //   setnumber("");
  //   setEmail("");
  // };
  return (
    <div>
      <div className="footerheader">
        <div className="footerconteainer">
          <div className="footeitem">
            <div className="imageside">
              <div className="icons">
                <TiSocialFacebookCircular />
              </div>

              <div className="icons">
                <TiSocialTwitterCircular />
              </div>

              <div className="icons">
                <TiSocialYoutube />
              </div>

              <div className="icons">
                <TiSocialInstagram />
              </div>
            </div>
            <div className="messageside">
              <h1>Connect with Vetex  Logistics</h1>
              <p>
                Stay connected on{" "}
                <span className="socla">
                  Instagram, Facebook, Twitter, LinkedIn{" "}
                </span>{" "}
                or for industry news and Vetex  updates.
              </p>
              {/* <h1>Newsletter Subscription</h1>
              <input type="text" placeholder="Subscribe to get the latest update" onChange={getaccount}/>
              <button onClick={handleNewSubmit}>Subscribe</button> */}
            </div>
          </div>

          <div className="feetnoot">
            <div className="sjehgdy">News & Updates</div>
            <div className="sjehgdy">Privacy Policy</div>
            <div className="sjehgdy">Forms-Downloads</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
