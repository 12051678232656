import React, { useEffect, useState } from "react";
import "./Banner.css";
import { Form, Button, Modal, ListGroup } from "react-bootstrap";
import {
  getTracker,
  getTrackerAccessKey,
  getuser,
} from "../../Services/GetUser.service";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../CreateUser.js/UserAuth";
import { endOfDay, set } from "date-fns";
import { format } from "date-fns";
import {  timeStamptoDate } from "../../Services/Utility";

function Banner() {
  const [inputTrack, setVal] = useState("");
  const [tracks, settrackitem] = useState([]);
  const [items, settracker] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleshow = () =>{
    history.push(`/shipment/tracker/${tracks.trackNo}`)
  };
  // const { uid } = useAuth().currentUser;
  const history = useHistory();
  const [message, setmessage] = useState("");

  useEffect(() => {
    if (tracks === null|| tracks === undefined ){
      setmessage("Please Enter Tour Track Number")
      return
    }
    
  
  }, [tracks])



  function getuserdetails(event) {
    setVal(event.target.value);
    const trackNo = event.target.value;
    if (trackNo.length === 15) {
      setmessage("Please Wait while fetching record")
      getTracker(trackNo).then((getitems) => {
        const activeitem = getitems;
        if (activeitem.length === 0 ){
          setmessage("No record found")
        }else{
          settrackitem(activeitem[0]);
          settracker(activeitem);
          setmessage("Record Found Please Click Search to proceed")
        } 
      }); 
       
    }
    
    }

   const  date =  format(new Date(), "' Date: 'yyyy-MM-dd 'Time:' hh:mm:a ")
   const convertedDate = timeStamptoDate(date)
   


  return (
    <div>
      <div
        className="bannercontainer"
        style={{ backgroundImage: "url(/img/b.jpg)" }}
      >


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Current Shipment Location</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalcontainer">
          <ListGroup variant="flush">
          <h1 >Personal Information</h1>
          <ListGroup.Item><strong>Name</strong> {tracks.firstname}{tracks.lastname}</ListGroup.Item>
          <ListGroup.Item><strong>Phone Number</strong> {tracks.personalphone}</ListGroup.Item>
          <ListGroup.Item><strong>email</strong> {tracks.email}</ListGroup.Item>
          <ListGroup.Item><strong>Track Number</strong> {tracks.trackNo}</ListGroup.Item>

          <h1>Reciver Information</h1>
          <ListGroup.Item><strong>Name</strong> {tracks.contactname}</ListGroup.Item>
          <ListGroup.Item><strong>Phone Number</strong> {tracks.contactnumber}</ListGroup.Item>
          <ListGroup.Item><strong>email</strong> {tracks.contactemail}</ListGroup.Item>

          <h1>Shipment Information</h1>
          <ListGroup.Item><strong>Weight</strong> {tracks.weigth}</ListGroup.Item>
          <ListGroup.Item><strong>Remark</strong> {tracks.comment}</ListGroup.Item>
          <ListGroup.Item><strong>Expected Delivery Date</strong> {tracks.deliverydate}</ListGroup.Item>
          <ListGroup.Item><strong>Payment Method</strong> {tracks.payment}</ListGroup.Item>     
          <h1>Status</h1>
          <ListGroup.Item><strong>Location</strong> {tracks.Destination}</ListGroup.Item>
          <ListGroup.Item><strong>Date</strong> {convertedDate}</ListGroup.Item>
    </ListGroup>

    <Link to={`/shipment/tracker/${tracks.trackNo}`}><Button>View Track History</Button></Link>
          </Modal.Body>

        </Modal>
        <div className="trankcontainer">
          <div className="inputselction">
            <div className="trackformcontene">
              <div className="trackformahs">
                <h2>Track Shipment</h2>
                <p>
                  Provide the required information to assist us narrow your
                  search
                </p>
              </div>
              <div className="tracform">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Track No</Form.Label>
                    <Form.Control
                      type="tel"
                      maxLength={15}
                      required
                      onChange={getuserdetails}
                    />
                  </Form.Group>

                  <Form.Text className="text-muted text-danger">
                    {message}
                  </Form.Text>

                  <Button
                    variant="dark"
                    className="w-100"
                    onClick={handleshow}
                  >
                    Search ...
                  </Button>
                </Form>
              </div>
            </div>
          </div>
          <div className="textsections">
            <h2>ON TIME. EVERY TIME. EVERYWHERE.</h2>
            <p>
              Let’s discover a smarter path to your customers — by ocean, air,
              or land — together. Since the start of the millennium, Vetex  Cargo has built lasting global partnerships on results, deep
              supply chain knowledge, and a powerful entrepreneurial approach
              that is anything but out-of-the-box.
            </p>

            <Button variant="dark" type="submit">
              Lean More ...
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
