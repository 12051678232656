
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage'
import "firebase/auth"


const FirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDUUZZvQUOAtaX17aLJYQPiokAtkeW69XQ",
  authDomain: "logistics-temp.firebaseapp.com",
  projectId: "logistics-temp",
  storageBucket: "logistics-temp.appspot.com",
  messagingSenderId: "314026984589",
  appId: "1:314026984589:web:1d8a807dd725c40e624ec4"
});

export const auth = FirebaseApp.auth()
export const database = FirebaseApp.firestore();
const storage = firebase.storage()



export  {
  storage,  FirebaseApp as default
}