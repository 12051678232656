import { database } from "../firebase";
import { TimestampDate } from "timestamp-date";

const timestampDate = new TimestampDate();

export const getuseraccount = (AccountNumber) => {
  return new Promise((resolve) => {
    const ref = database.collection("user");
    let reference = ref;

    if (AccountNumber) {
      reference = reference.where("AccountNumber", "==", AccountNumber);
    }
    reference.onSnapshot((queryproduct) => {
      const items = [];
      queryproduct.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      

      resolve(items);
    });
  });
};

export const getuser = (id, cb) => {
    const ref = database.collection("user");
    let reference = ref;
    if (id) {
      reference = reference.where("id", "==", id);
    }
    reference.onSnapshot((queryproduct) => {
      const items = [];
      queryproduct.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(item);
      });
      cb(items);
  });
};


export const getallrequest = (lastDoc) => {
  return new Promise((resolve) => {
    let reference = database.collection("services").orderBy("created", "desc");

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }

    reference
      .limit(40)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};


// export const getUsersEmail = (email) => {
//   return new Promise(resolve => {
//       let reference = EmailRef;

//       if (email) {
//           reference = reference.where('email', '==', email);
//       }
//       reference.onSnapshot((queryuser) => {
//           const items = [];
//           queryuser.forEach((doc) => {
//             const item = doc.data()
//             item.id=doc.id
//             items.push(item);
//           });

//           resolve(items);
//       });
//   });
// }


export const getallTracker = (lastDoc) => {
  return new Promise((resolve) => {
    let reference = database.collection("Tracker").orderBy("created", "desc");

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }

    reference
      .limit(40)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};

export const getTracker = (trackNo, lastDoc) => {

  return new Promise((resolve) => {
    let reference = database.collection("Tracker").orderBy("created", "desc");

    if (trackNo) {
      reference = reference.where("trackNo", "==", trackNo);

    }

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }


    reference
      .limit(40)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};



export const getTrackerItem = (trackNo, lastDoc) => {
  return new Promise((resolve) => {
    let reference = database.collection("Tracker").orderBy("created", "desc");

    if (trackNo) {
      reference = reference.where("trackNo", "==", trackNo);

    }

    if (lastDoc) {
      reference = reference.startAfter(lastDoc.__doc);
    }
   reference
      .limit(40)
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};

export const getalltraker = (trackNo, cd) => {

    let reference = database.collection("Tracker").orderBy("created", "desc");

    if (trackNo) {
      reference = reference.where("trackNo", "==", trackNo);

    }
   reference
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        cd(items);
      });

};

export const getmessage = (lastDoc , cd) => {

  const ref = database.collection("message");
  let reference = ref;

  
  if (lastDoc) {
    reference = reference.startAfter(lastDoc.__doc);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate (item));
    });

    cd(items);

    
  });
};


export const getTrackerAccessKey = (AccessKey, lastDoc) => {

  return new Promise((resolve) => {
    let reference = database.collection("Tracker")

    if (AccessKey) {
      reference = reference.where("AccessKey", "==", AccessKey);

    }

    reference
      .get()
      .then((querylogs) => {
        const items = [];
        querylogs.forEach((doc) => {
          const item = doc.data();
          item.id = doc.id;
          item.__doc = doc;
          items.push(timestampDate.parseTimestampToDate(item));
        });
        resolve(items);
      });
  });
};