import React, { useEffect, useState } from 'react'
import Navbar from "./Navbar"
import Footer from "./Footer"
import { useHistory } from 'react-router-dom'
import {Form, Button}  from 'react-bootstrap'
import "./Banner.css"
import { getTracker} from '../../Services/GetUser.service'

function TrackShipment() {
  const history =  useHistory()
  const [trackno, setVal] = useState("")
  const [message, setmessage] = useState("")
  const [trackitem, settrackitem] = useState("")
  const [show, setShow] = useState(false);

  const  handlecontace = () =>{
    history.push('/contact')
    
  }

  function handleroute (){
   if (trackitem){
    history.push(`/shipment/tracker/${trackitem.trackNo}`)
   }
  }


  useEffect(() => {
    if (trackitem === null|| trackitem === undefined ){
      setmessage("Please Enter Tour Track Number")
      return
    }
  }, [trackitem])


function getuserdetails(event) {
  setVal(event.target.value);
  const trackNo = event.target.value;
  if (trackNo.length === 15) {
    setmessage("Please Wait while fetching record")
    getTracker(trackNo).then((getitems) => {
      const activeitem = getitems;
      if (activeitem.length === 0 ){
        setmessage("No record found")
      }else{
        settrackitem(activeitem[0]);
        handleroute()
        setmessage("Record Found Please Click Search to proceed")
      } 
    }); 
     
  }

  
  }

 


  return (
    <div>
        <Navbar/>
        <div className="Trackingcontainer" style={{ backgroundImage: "url(/img/b.jpg)" }}>
            <div className="trackingcontentr">
            <div className="inputselction111">
            <div className="trackformcontene">
              <div className="trackformahs">
                <h2>Tracker</h2>   
              </div>
              <div className="tracform">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Track No</Form.Label>
                    <Form.Control
                      type="tel"
                      required
                      onChange={getuserdetails}
                    />
                  </Form.Group>
                  {message}

                  <Button variant="dark" className="w-100" onClick={handleroute}>
                    Search ...
                  </Button>
                </Form>
              </div>
            </div>
          </div>
          <Button className='text-center bg-black' onClick={handlecontace}>Make Enquiry</Button>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default TrackShipment